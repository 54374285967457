

@font-face {
    font-family: font-poppins;
    src: url("../fonts/Poppins-Regular.ttf");
}

* {
    font-family: font-poppins;
}

/* h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 0;
} */


.fs-13{
    font-size: 13px !important;
}
.fs-11{
    font-size: 11px !important;
}

.fs-20{
    font-size: 20px;
}

.icon {
    display: inline-block;
    font-size: inherit;
    /* Inherits font-size from its parent, like an icon font */
    width: 1em;
    /* Scales based on the font-size */
    height: 1em;
    /* Scales based on the font-size */
    color: inherit;
    /* Inherits text color from its parent */
}