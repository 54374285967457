$primary: #350023;
$secondary: #4b85f2;
$success:  #5A9D05;
$info:  #EFEFEF;
// $warning:  #6A7B6F;
$light:  #F3DFEC;
// $danger:  darkred;
// $dark:  dimgrey;




// $form-check-input-checked-color:          --bs-success-rgb;
// $form-check-input-checked-bg-color:    --bs-danger-rgb;
// $form-check-input-checked-border-color:   --bs-danger-rgb;

@import '../../../node_modules//bootstrap/scss/bootstrap.scss';
@import "./calendar.scss";



.nav-pills .nav-link {
    &.active {
        // @extend .bg-white;
        // @extend .bg-opacity-25;
        @extend .rounded-pill;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 50px -10px, rgba(0, 0, 0, 0.04) 0px 10px 50px -10px;
        background: #ffffff20 !important;
        border: 1px solid #ffffff20;
        transition: all 0.5s ease;
    }

    &:not(.active) {
        @extend .text-white;
        @extend .opacity-50;
        border: 1px solid #ffffff00;
        transition: all 0.5s ease;
    }
}

.nav-pills .nav-link:hover {
    color: #fff;
    opacity: 1 !important;
}

// .nav-pills .nav-link {
//     &.active {
//         @extend .bg-white;
//         @extend .bg-opacity-25;
//         // @extend .alert-primary;
//         // @extend .border;
//         // @extend .border-primary;
//         // @extend .border-5;
//         // @extend .border-top-0;
//         // @extend .border-bottom-0;
//         // @extend .border-start-0;
//         // @extend .px-3;
//         // padding-top: 0.5rem;
//         // padding-bottom: 0.5rem;
//         // @extend .m-0;
//         transition: all 0.1s ease-in-out;
//     }

//     &:not(.active) {
//         @extend .text-white;
//         @extend .opacity-50;
//     }
// }
